import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GraphOptions } from 'src/model/GraphOptions'
import { ProductQuotesTypeEnum } from '../../../../../tools/enums';
import { HighchartsService } from '../../../../services/charts/highcharts.service';
import { HighstockService } from '../../../../services/charts/highstock.service';

@Component({
  selector: 'price_chart',
  templateUrl: './price_chart.component.html',
  styleUrls: ['./price_chart.component.scss']
})

export class PriceChartComponent  implements OnInit {
  dialogRef: MatDialogRef<PriceChartComponent>;
  productCode: string = '';
  productName: string = '';
  codeExpiry: string = '';
  textExpiry: string = '';
  graphTitle: string = '';
  pricesType: string = '';
  unit: string = '';
  highchartsService: HighchartsService
  highstockService: HighstockService;  
  productQuotesType: ProductQuotesTypeEnum;
  currentInterval: string = '';
  defaultInterval: string = '1m';
  defaultCssClassInterval: string = 'lblRefreshGraphInterval'
  cssClassIntervalSelected: string = 'lblRefreshGraphIntervalSelected'
  cssClassIntervalFirst: string = 'lblRefreshGraphIntervalFirst'
  data: Array<any>;
  multiplier: number;

  @ViewChild('charts') public charts: ElementRef;
  @ViewChild('charts2') public charts2: ElementRef;
  constructor(private _highchartsService: HighchartsService, private _highstockService: HighstockService) {


    this.currentInterval = this.defaultInterval;
    this.highchartsService = _highchartsService;
    this.highstockService = _highstockService;
  }

  ngOnInit(): void {
    
  }

  RefreshGraph(graphOptions: GraphOptions) {    
  }

  ClosePopup() {
    this.dialogRef.close();
  }
}
