<div class="question-banner">
  <div class="title-container">
    <div class="title">
      <p>Une question de marché, de tendance ?</p>

    </div>
  </div>
  <div class="text-container">
    <p> Cliquez sur le lien et posez-nous votre question ! Nous y répondrons en vidéo dans le prochain épisode de 3 min
      chrono. <a href="https://alabordage.captainfarmer.com/3_min_chrono_question-0" target="_blank"
        class="ask-question"> Posez vos question ici ! </a>
    </p>
  </div>
</div>