import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { ReportService } from 'src/app/services/http/report.service';
import { ReportNotificationType, ReportType } from 'src/model/ReportType';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-report-notification',
  templateUrl: './report-notification.component.html',
  styleUrls: ['./report-notification.component.scss'], 
  providers:[]
})
export class ReportNotificationComponent implements OnInit {

  constructor(private reportService: ReportService, private notificationService: NotificationService, private breakpointObserver: BreakpointObserver) { }

  values: any[] = [];
  checked: boolean = null;
  isHidden: boolean = false;
  hideIconDesktop: string = "block";
  toggleIcon: string = "keyboard_arrow_down";
  isDesktop: boolean = this.breakpointObserver.isMatched('(max-width: 768px)');

  ngOnInit(): void {
    this.loadData();

    if (!this.isDesktop) {
      this.hideIconDesktop = "none";
    }
  }
  reportNotificationForm = new FormGroup({
    1: new FormControl(null),
    2: new FormControl(null),
    3: new FormControl(null),
    4: new FormControl(null),

  })

  onSubmit() {

    const userSentSaveReports = [
      {
        "idReportType": 1,
        "isActivated": this.reportNotificationForm.value[1],
      },
      {
        "idReportType": 2,
        "isActivated": this.reportNotificationForm.value[2],
      },
      {
        "idReportType": 3,
        "isActivated": this.reportNotificationForm.value[3],
      },
      {
        "idReportType": 4,
        "isActivated": this.reportNotificationForm.value[4],
      },
    ]


    this.reportService.SaveSentReportUser(userSentSaveReports).subscribe({
      error: (err: { status: number; }) => {
        if (err.status != 401) {
          this.notificationService.ShowError("Erreur", "Erreur lors de l'envoi de vos préferences")
        }
      },
      complete: () => {
        this.notificationService.ShowSuccess('Vos préférences ont bien été enregistrées', '')
      }

    })

  }

  loadData() {
    this.reportService.GetSentReportUser().subscribe({
      next: (data: ReportNotificationType[]) => {
        data.forEach(element => {
          this.values.push(element); 
        });
      }
    })
  }

  closePanel() {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
    if (isSmallScreen) {
      this.isHidden = !this.isHidden;
      if (this.isHidden) {
        this.toggleIcon = "keyboard_arrow_up";
      } else {
        this.toggleIcon = "keyboard_arrow_down";
      }
    }
    else {
      this.isHidden = false;    
    }
  }
}