<div class="component-container">

  <div class="scrollY {{classBlurred}} ">
    <!-- Header strategy -->
    <app-strategy-header [strategyHeaderProductId]="sentProductId"></app-strategy-header>
    <div>
      <div class="strategy-container">
        <app-strategy [receivedProductId]="sentProductId" [strategyId]="1"></app-strategy>
        <app-strategy [receivedProductId]="sentProductId" [strategyId]="2"></app-strategy>
      </div>
      <app-expert-opinion [productId]="sentProductId"></app-expert-opinion>
    </div>
  

    <!-- Header Opinion  -->
    <app-expert-opinion-header [expertOpinionHeaderProductId]="sentProductId"></app-expert-opinion-header>

    <app-decrypt [productId]="sentProductId"></app-decrypt>
    <app-chart [productId]="1"></app-chart>
    <app-focus [productId]="sentProductId"></app-focus>

  </div>

</div>