<div class="main-analysis-container">
  <div class="analysis-nav">
    <app-analysis-nav></app-analysis-nav>
  </div>
  <div class="analysis-container">
    <router-outlet></router-outlet>
  </div>
  <div class="question-banner">
    <app-question-banner></app-question-banner>
  </div>
</div>