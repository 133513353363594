import { Component } from '@angular/core';

@Component({
  selector: 'app-question-banner',
  templateUrl: './question-banner.component.html',
  styleUrl: './question-banner.component.scss'
})
export class QuestionBannerComponent {

}
